@import 'basestyle.css';
@import "./comon.function.scss";

:root, .base-color-theme {

  --main-content-width: calc( 100% - 355px );
  --max-width: $container-width;
  --border-radius: 12px;

  // アイコンパス
  --clip-ssvg-icon: url( "/static-icons/clip.svg" );
  --circle-chevron-right-svg: url("/static-icons/circle-chevron-right-solid.svg");

  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-color: 0, 0, 0;
  --foreground-color-secondary: gray;
  --foreground-color-code: orangered;

  //--background-image-main: linear-gradient(to right bottom, #7900ff, #6750ff, #6174ff, #6d91ff, #87abff, #87abff, #87abff, #87abff, #6d91ff, #6174ff, #6750ff, #7900ff);;
  --background-image-main: white;
  --background-main-color: 244, 249, 240;
  --background-primary-color: #cdcdcd;
  --background-primary-dark-color: #a5a5a5;
  --background-secondary-color: rgb(204, 225, 221);
  --background-color-code: #fbfbfb;
  --discussion-background-color: #f1f1f1;
  --categories-dropdown-background: #fbfbfb;

  --primary-color: violet;
  --secondary-color: mediumturquoise;

  // スクロールバー
  --scrollbar-track-color: rgb(210, 210, 210);

  //--loading-background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(0,152,155,1) 0.1%, rgba(0,94,120,1) 94.2% );
  --loading-background-image: var( --background-main-color );

  --border-color: rgb(64, 74, 77);
  --border-color-secondary: rgb(104, 117, 121);

  --input-border-underline: rgb(0, 85, 255);

  --admin-bar-background: var( --background-primary-color );

  // code block
  --ck-code-block-color: orange;
  --ck-code-block-border: 1px solid #a5a5a5;
  --ck-code-block-background-color: hsl(0deg 0% 74.16% / 30%);

  // React Select
  --react-select-neutral0: rgb(237, 237, 237);
  --react-select-primary: var( --primary-color );
  --react-select-primary25: rgb(189, 189, 189);

  --container-max-width: 1300px;

  // tocbot
  --tocbot-selected-background: #e7e7e7;
}

@mixin base-dark-theme() {

  --foreground-color: 255, 255, 255;
  --foreground-color-secondary: rgb(181, 181, 181);
  --foreground-color-code: #ffaf1c;

  //--background-image-main: linear-gradient(to right bottom, #000000, #290a19, #460434, #5f0058, #6e0086, #6e0086, #6e0086, #6e0086, #5f0058, #460434, #290a19, #000000);
  --background-image-main: black;
  --background-main-color: 0, 0, 0;
  --background-primary-color: #1e1d1d;
  --background-primary-dark-color: black;
  --background-secondary-color: rgb(45 49 49);
  --background-color-code: var( --background-primary-color );
  --discussion-background-color: var( --background-primary-color );
  --categories-dropdown-background: var( --background-primary-dark-color );

  --primary-color: darkOrchid;
  --secondary-color: mediumturquoise;

  // スクロールバー
  --scrollbar-track-color: rgb(65, 65, 65);

  //--loading-background-image: linear-gradient(118.4deg, rgb(122, 35, 52) 27.5%, rgb(62, 9, 27) 92.7%);
  --loading-background-image: var( --background-main-color );

  --border-color: rgb(81, 86, 88);
  --border-color-secondary: rgb(160, 180, 186);

  --admin-bar-background: var( --background-secondary-color );

  --callout-rgb: 20, 20, 20;
  --callout-border-rgb: 108, 108, 108;
  --card-rgb: 100, 100, 100;
  --card-border-rgb: 200, 200, 200;

  // React Select
  --react-select-neutral0: rgb(47, 47, 47);
  --react-select-primary: var( --primary-color );
  --react-select-primary25: rgb(87, 87, 87);

  // tocbot
  --tocbot-selected-background: var( --background-primary-color );
}

:root.ck-theme-dark {
  /* Overrides the border radius setting in the theme. */
  --ck-border-radius: 4px;

  /* Overrides the default font size in the theme. */
  --ck-font-size-base: 14px;

  /* Helper variables to avoid duplication in the colors. */
  --ck-color-base-background: hsl(0deg 0% 4.08%);
  --ck-powered-by-background: rgb(64, 64, 64);
  --ck-powered-by-text-color: white;
  --ck-color-base-border: rgb(81 81 81);
  --ck-custom-background: hsl(0deg 0% 11.17%);
  --ck-custom-foreground: hsl(255, 3%, 18%);
  --ck-custom-border: hsl(300, 1%, 22%);
  --ck-custom-white: hsl(0deg 0% 11.17%);
  

  /* -- Overrides generic colors. ------------------------------------------------------------- */

  --ck-color-base-foreground: var(--ck-custom-background);
  --ck-color-focus-border: hsl(208, 90%, 62%);
  --ck-color-text: hsl(0, 0%, 98%);
  --ck-color-shadow-drop: hsla(0, 0%, 0%, 0.2);
  --ck-color-shadow-inner: hsla(0, 0%, 0%, 0.1);

  /* -- Overrides the default .ck-button class colors. ---------------------------------------- */

  --ck-color-button-default-background: var(--ck-custom-background);
  --ck-color-button-default-hover-background: hsl(270, 1%, 22%);
  --ck-color-button-default-active-background: hsl(270, 2%, 20%);
  --ck-color-button-default-active-shadow: hsl(270, 2%, 23%);
  --ck-color-button-default-disabled-background: var(--ck-custom-background);

  --ck-color-button-on-background: var(--ck-custom-foreground);
  --ck-color-button-on-hover-background: hsl(255, 4%, 16%);
  --ck-color-button-on-active-background: hsl(255, 4%, 14%);
  --ck-color-button-on-active-shadow: hsl(240, 3%, 19%);
  --ck-color-button-on-disabled-background: var(--ck-custom-foreground);

  --ck-color-button-action-background: hsl(168, 76%, 42%);
  --ck-color-button-action-hover-background: hsl(168, 76%, 38%);
  --ck-color-button-action-active-background: hsl(168, 76%, 36%);
  --ck-color-button-action-active-shadow: hsl(168, 75%, 34%);
  --ck-color-button-action-disabled-background: hsl(168, 76%, 42%);
  --ck-color-button-action-text: var(--ck-custom-white);

  --ck-color-button-save: hsl(120, 100%, 46%);
  --ck-color-button-cancel: hsl(15, 100%, 56%);

  /* -- Overrides the default .ck-dropdown class colors. -------------------------------------- */

  --ck-color-dropdown-panel-background: var(--ck-custom-background);
  --ck-color-dropdown-panel-border: var(--ck-custom-foreground);

  /* -- Overrides the default .ck-splitbutton class colors. ----------------------------------- */

  --ck-color-split-button-hover-background: var(--ck-color-button-default-hover-background);
  --ck-color-split-button-hover-border: var(--ck-custom-foreground);

  /* -- Overrides the default .ck-input class colors. ----------------------------------------- */

  --ck-color-input-background: var(--ck-custom-background);
  --ck-color-input-border: hsl(257, 3%, 43%);
  --ck-color-input-text: hsl(0, 0%, 98%);
  --ck-color-input-disabled-background: hsl(255, 4%, 21%);
  --ck-color-input-disabled-border: hsl(250, 3%, 38%);
  --ck-color-input-disabled-text: hsl(0, 0%, 78%);

  /* -- Overrides the default .ck-labeled-field-view class colors. ---------------------------- */

  --ck-color-labeled-field-label-background: var(--ck-custom-background);

  /* -- Overrides the default .ck-list class colors. ------------------------------------------ */

  --ck-color-list-background: var(--ck-custom-background);
  --ck-color-list-button-hover-background: var(--ck-color-base-foreground);
  --ck-color-list-button-on-background: var(--ck-color-base-active);
  --ck-color-list-button-on-background-focus: var(--ck-color-base-active-focus);
  --ck-color-list-button-on-text: var(--ck-color-base-background);

  /* -- Overrides the default .ck-balloon-panel class colors. --------------------------------- */

  --ck-color-panel-background: var(--ck-custom-background);
  --ck-color-panel-border: var(--ck-custom-border);

  /* -- Overrides the default .ck-toolbar class colors. --------------------------------------- */

  --ck-color-toolbar-background: var(--ck-custom-background);
  --ck-color-toolbar-border: var(--ck-custom-border);

  /* -- Overrides the default .ck-tooltip class colors. --------------------------------------- */

  --ck-color-tooltip-background: hsl(252, 7%, 14%);
  --ck-color-tooltip-text: hsl(0, 0%, 93%);

  /* -- Overrides the default colors used by the ckeditor5-image package. --------------------- */

  --ck-color-image-caption-background: hsl(0, 0%, 97%);
  --ck-color-image-caption-text: hsl(0, 0%, 20%);

  /* -- Overrides the default colors used by the ckeditor5-widget package. -------------------- */

  --ck-color-widget-blurred-border: hsl(0, 0%, 87%);
  --ck-color-widget-hover-border: hsl(43, 100%, 68%);
  --ck-color-widget-editable-focus-background: var(--ck-custom-white);

  /* -- Overrides the default colors used by the ckeditor5-link package. ---------------------- */

  --ck-color-link-default: hsl(190, 100%, 75%);

  // code block
  --ck-code-block-color: white;
  --ck-code-block-border: 1px solid gray;
  --ck-code-block-background-color: hsl(0deg 0.71% 30.64% / 30%);
}

@media ( prefers-color-scheme: dark ) {
  :root {
    @include base-dark-theme();
  }
}

:root.dark-theme {
  @include base-dark-theme();
  color-scheme: dark;
}

:root.light-theme {
  @extend .base-color-theme;
  color-scheme: light;
}

// スクロールバーカスタマイズ
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
  border: none;
  border-radius: 10px;
  box-shadow: inset 0 0 2px #777;
  display: none;
}

::-webkit-scrollbar-corner {
  display: none;
}

::-webkit-scrollbar-thumb {
  background: var( --scrollbar-track-color );
  border-radius: 10px;
  box-shadow: none;
}
/*
::-webkit-scrollbar-thumb:horizontal {
  background: rgb(65, 65, 65);
  border-radius: 10px;
  box-shadow: none;
}*/

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
  &:focus { outline: none; }
  &::selection {
    color: white;
    background: var( --primary-color );
  }
}

html,
body {
  //position: relative;
  //padding-bottom: 50px;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  //align-content: start;
  align-items: start;
  max-width: 100vw;
  min-height: 100vh;
  width: 100%;
  //overflow-x: hidden;
}

body {
  //font-family: var( --bs-font-sans-serif ) !important;
  color: rgb(var(--foreground-color));
  background-color: rgb( var( --background-main-color ) );
  background-image: var( --background-image-main );
  background-size: cover;
  background-attachment: fixed;
  //opacity: 0;
  transition: all .4s;
}

div.container, article.container, ul.container {
  display: block;
  width: 100%;
  margin: 0 auto;
  max-width: var( --container-max-width );
}

main, article, section {
  display: block;
  width: var( --main-content-width );
}

h1 { font-size: 26pt; }
h2 { font-size: 22pt; margin: 20px auto; }
h3 { font-size: 18pt; margin: 20px auto; }
h4 { font-size: 14pt; margin: 20px auto; }

p {
  font-size: 18.5px;
  margin-bottom: 1.5em;
  @include sp_base() {
    font-size: 17px;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

#insert_post_html p > a, #insert_post_html li > a,
#insert_post_html th > a, #insert_post_html td > a,
div.ck.ck-editor__main p > a, div.ck.ck-editor__main li > a,
div.ck.ck-editor__main th > a, div.ck.ck-editor__main td > a {
  color: violet;
  text-decoration: underline;
  margin-left: 5px;
  margin-right: 5px;
}

a.inline-decoration {
  color: var( --primary-color );
  text-decoration: underline;
}

ul {
  display: block;
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0;
}

button {
  position: relative;
  //border: 1px solid rgb( var( --border-color ) );
  padding: 0;
  border: none;
  background-color: transparent;
  color: var( --foreground-color );
  &:active::before, &:disabled::before {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    content: "";
    z-index: 1;
  }
}

pre {
  display: block;
  position: relative;
  > span.lang_name {
    position: absolute;
    top: 0; left: 0;
    padding: 2px 15px;
    background-color: gray;
    color: white;
  }
  > code {
    padding-top: 2.5em !important;
    font-size: 16px !important;
  }
}

p > code, li > code, td > code, th > code {
  color: var( --foreground-color-code ) !important;
  padding: 0px 4px 2px 4px;
  border: 1px solid var( --foreground-color-code );
  margin-left: 5px;
  margin-right: 5px;
}

dialog {
  position: fixed;
  top: 30%; left: 50%;
  transform: translate( -50%, -50% );
  border: 1px solid var( --border-color );
  border-radius: 10px;
  padding: 15px;
  max-width: 85vw;
  max-height: 85vh;
  transition: all .3s ease-in-out;
  &::backdrop {
    background-color: transparent;
    transition: all .3s ease-in-out;
  }
}
dialog.show::backdrop {
  backdrop-filter: blur( 3px );
}

input, select, textarea {
  border: none;
  outline: none;
  &:focus {
    border: none;
    outline: none;
  }
}

fieldset {
  margin-inline-start: unset;
  margin-inline-end: unset;
  padding-block-start: unset;
  padding-block-end: unset;
  padding-inline-start: unset;
  padding-inline-end: unset;
  min-inline-size: unset;
  legend {
    padding-inline-start: unset;
    padding-inline-end: unset;
  }
}

figcaption {
  background-color: var( --background-secondary-color );
  padding: 5px;
  text-align: center;
}

input[type="text"], input[type="search"],
input[type="email"], textarea, select {
  background-color: var( --background-secondary-color );
}

// animate.css
.animate__animated.animate__fadeIn {
  --animate-duration: 1000ms !important;
  --animate-delay: 0.2s !important;
}

figure > img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}

.hljs {
  background: #191e25 !important;
  border: 1px dotted gray !important;
}

@include sp_base() {
  figure {
    margin: 0;
    margin-bottom: 15px;
    overflow-x: auto;
    width: 100%;
    > table {
      width: 100% !important;
      min-width: 650px;
    }
  }
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}